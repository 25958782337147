import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";

import { toast } from "react-toastify";
import {
  createSector,
  fetchSectors,
} from "../../../redux/slices/producersSlice";

export default function ModalSector() {
  const [showModal, setShowModal] = useState(false);

  const status = useSelector((state) => state.producer?.status);

  const dispatch = useDispatch();

  const initialFormData = {
    name: "",
    note: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(createSector(formData));
      await dispatch(fetchSectors());
      toast.success(res.payload.message);
      setFormData({ ...initialFormData });
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        <PiPlusBold className=" text-lg font-extrabold" />
        Create Sector
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 max-w-3xl w-full">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Create a Sector
                  </h1>
                </div>
                <div className="flex justify-center items-center w-full px-8 py-4">
                  <div className="flex flex-col gap-4 w-full">
                    <div>
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                      />
                    </div>
                    <div className="flex flex-col ">
                      <label htmlFor="note" className="text-left">Note</label>
                      <textarea
                        name="note"
                        id="note"
                        // cols="20"
                        rows="3"
                        value={formData.note}
                        onChange={handleInputChange}
                        placeholder="Type here..."
                        className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7] h-24"
                      />
                    </div>

                    <div className="flex mt-4 gap-4 justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleSubmit}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Creating ...
                            </p>
                          </>
                        ) : (
                          <>
                            <PiPlusBold className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Create Sector
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
