import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaUsers,
  FaChevronDown,
  FaChevronUp,
  FaFirstOrder,
  FaGasPump,
  FaClipboardList,
} from "react-icons/fa";
import { BiMenuAltRight, BiSolidDashboard, BiSolidStore } from "react-icons/bi";
import { GiWashingMachine } from "react-icons/gi";
import { MdAdminPanelSettings } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { LuMonitorDown } from "react-icons/lu";
import { TbFileSettings, TbTruckDelivery } from "react-icons/tb";
import { BsBank, BsFillShieldLockFill } from "react-icons/bs";
import { SiTransportforlondon } from "react-icons/si";
import { FaHandshakeSimple, FaMapLocationDot, FaShop } from "react-icons/fa6";
import LogoImg from "../assets/full.png";
import LogoImg2 from "../assets/logo.png";
import { CgTrack } from "react-icons/cg";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayment } from "../redux/slices/paymentSlice";
import { fetchRemit } from "../redux/slices/remitSlice";
import { fetchWithdrawal } from "../redux/slices/withdrawalSlice";
import { fetchLaundryOngoing } from "../redux/slices/laundryOngoingSlice";

const Sidebar = () => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const storedUser = JSON.parse(localStorage.getItem("user"));

  const userFunctions = storedUser.functions;
  const userRole = storedUser.role;

  useEffect(() => {
    dispatch(fetchPayment());
    dispatch(fetchRemit());
    dispatch(fetchWithdrawal());
    dispatch(fetchLaundryOngoing());
  }, [dispatch]);

  const payments = useSelector((state) => state.payment?.payments);
  const withdrawals = useSelector((state) => state.withdrawal?.withdrawals);
  const remits = useSelector((state) => state.remit?.remits);

  const shopperpayments = payments?.filter(
    (payment) => payment?.type === "shopper" || payment?.type === "Shopper"
  );
  const driverpayments = payments?.filter(
    (payment) => payment?.type === "Driver" || payment?.type === "driver"
  );

  const shopperWithdrawals = withdrawals?.filter(
    (withdrawal) =>
      withdrawal?.type === "shopper" || withdrawal?.type === "Shopper"
  );
  const driverWithdrawals = withdrawals?.filter(
    (withdrawal) => withdrawal?.type === "driver"
  );

  const shopperRemits = remits?.filter((remit) => remit?.type === "shopper");
  const driverRemits = remits?.filter((remit) => remit?.type === "driver");

  const laundryOngoings = useSelector(
    (state) => state.laundryOngoing?.laundryOngoings
  );

  const expressLaundryOrders = laundryOngoings.filter(
    (order) => order.express === "1"
  );

  const [showSubNav, setShowSubNav] = useState({
    Shopping: false,
    Laundry: false,
  });

  const toggleSubNav = (navLabel) => {
    setShowSubNav({
      ...showSubNav,
      [navLabel]: !showSubNav[navLabel],
    });
  };

  const allNavItems = [
    {
      icon: <TbFileSettings />,
      label: "Fee Settings",
      subNav: [
        { to: "fee-setting/laundy-fee-setting", label: "Laundry Fee Setting" },
        {
          to: "fee-setting/shopping-fee-setting",
          label: "Shopping Fee Setting",
        },
        {
          to: "fee-setting/invesmtent-fee-setting",
          label: "Investment Fee Setting",
        },
        {
          to: "fee-setting/transcation-fee-setting",
          label: "Wallet Transaction Fee Setting",
        },
        {
          to: "fee-setting/registration-fee",
          label: "Registration Fee",
        },

        
      ],
    },
    {
      icon: <BsBank />,
      label: "Bank Payment",
      subNav: [
        { to: "bank-payment/cart-shopping", label: "Cart Shopping" },
        {
          to: "bank-payment/personalize-shopping",
          label: "Personalize Shopping",
        },
        {
          to: "bank-payment/fuel-gas",
          label: "Fuel/Gas",
        },
        {
          to: "bank-payment/wallet-funding",
          label: "Wallet Funding",
        },
        {
          to: "bank-payment/laundry",
          label: "Laundry",
        },
        {
          to: "bank-payment/completed-bank-transfer",
          label: "Completed Bank Transfer",
        },
        {
          to: "bank-payment/requested-transfer",
          label: "Requested Transfers",
        },
      ],
    },
    {
      icon: <FaFirstOrder />,
      label: "Shopping Orders",
      subNav: [
        { to: "orders/carts-orders", label: "Cart Orders" },
        { to: "orders/order-list", label: " All Orders" },
        { to: "orders/pending-orders", label: "Pending Orders" },
        { to: "orders/express-orders", label: " Express Orders" },
        { to: "orders/ongoing-orders", label: "Ongoing Orders" },
        { to: "orders/pending-deliveries", label: "Pending Deliveries" },
        { to: "orders/delivered-orders", label: "Delivered Orders" },
        { to: "orders/pay-on-delivery", label: "Pay On Delivery" },

        { to: "orders/future-deliveries", label: "Future Deliveries" },
        { to: "orders/completed-orders", label: "Completed Orders" },
        { to: "orders/cancelled-orders", label: "Cancelled Orders" },
        { to: "orders/reported-orders", label: "Shopping Reported Orders" },
        { to: "orders/shopping-subscription", label: "Shopping Subscription" },
      ],
    },
    {
      icon: <LuMonitorDown />,
      to: "monitor",
      label: "Monitor Orders",
    },
    {
      icon: <BiSolidStore />,
      label: "Stores",
      subNav: [
        { to: "shopping/markets", label: "Market" },
        { to: "shopping/stores", label: "Stores" },
        { to: "shopping/fast-food", label: "Fast Food" },
        { to: "shopping/pharmacy", label: "Pharmacy" },
        { to: "shopping/categories", label: "Categories" },
        { to: "shopping/products", label: "Products" },
        { to: "shopping/trending-products", label: "Trending Products" },
        { to: "shopping/price-feedback", label: "Price Feedback" },
      ],
    },
    {
      icon: <FaClipboardList />,
      label: "Producer",
      subNav: [
        { to: "producer/producers", label: "All Producers" },
        { to: "producer/sectors", label: "All Sectors" },
        { to: "producer/pending-approvals", label: "Pending Product Approval" },
        { to: "producer/approved-products", label: "Approved Products" },
        { to: "producer/active-subscribers", label: "Active Subscribers" },
        { to: "producer/free-subscribers", label: "Free Subscribers" },
        { to: "producer/producer-vendor-subscriptions", label: "Producer & Vendor Subscriptions" },
        
      ],
    },
    {
      icon: <FaShop />,
      label: "Vendor",
      subNav: [
        { to: "vendor/vendors", label: "All Vendors" },
        { to: "vendor/pending-products", label: "Pending Product Approval" },
        { to: "vendor/approved-products", label: "Approved Products" },
      ],
    },

    {
      icon: <FaHandshakeSimple />,
      label: "Trade Fair",
      subNav: [
        { to: "tradefair/pending-tradefair", label: "Pending Trade Fair" },
        { to: "tradefair/approved-tradefair", label: "Approved Trade Fair" },
      ],
    },
    {
      icon: <GiWashingMachine />,
      label: "Laundry",
      subNav: [
        { to: "laundry/laundry-cart", label: " Laundry cart orders" },
        { to: "laundry/laundry-list", label: "All Laundry Orders" },
        { to: "laundry/laundry-express", label: "Express Orders" },
        {
          to: "laundry/pending-payments-laundry",
          label: "Pending Payment Laundry",
        },
        { to: "laundry/completed-orders", label: " Completed Orders" },
        { to: "laundry/cancelled-orders", label: " Cancelled Orders" },
        {
          to: "laundry/laundry-reported-orders",
          label: "Laundry Reported Orders",
        },
        { to: "laundry/laundry-store", label: "Laundry Store" },
        { to: "laundry/laundry-drop-off", label: "Laundry Drop Off" },
        { to: "laundry/laundry-subscription", label: "Laundry Subscription" },
      ],
    },

    {
      icon: <CgTrack />,
      label: "Track laundry Order",
      subNav: [
        { to: "tracks/orders-placed", label: "Order Placed" },
        { to: "tracks/items-received", label: "Items received" },
        { to: "tracks/ongoing-orders", label: "Ongoing Orders" },
        { to: "tracks/ready-for-delivery", label: "Ready for delivery" },
        { to: "tracks/items-delivered", label: "Items Delivered" },
        { to: "tracks/disputed-orders", label: " Disputed Orders" },
      ],
    },
    {
      icon: <IoIosPeople />,
      label: "Shoppers",
      subNav: [
        { to: "shoppers/shopper-list", label: "All Shoppers" },
        {
          to: "shoppers/Withdrawal",
          label: "Shopper Withdrawals",
        },
        {
          to: "shoppers/Remit",
          label: "Shopper Remittance",
        },
        {
          to: "shoppers/payment",
          label: "Shopper Payment Request",
        },
        { to: "shoppers/completed-payment", label: "Completed Payment" },
        { to: "shoppers/promotion", label: "Shopper Promotion" },
        { to: "shoppers/store-Locator", label: "Store Locator" },
        { to: "shoppers/deletion-request", label: "Shopper Deletion Request" },
        { to: "shoppers/seller-info", label: "Seller Info" },
      ],
    },
    {
      icon: <TbTruckDelivery />,
      label: "Drivers",
      subNav: [
        { to: "drivers/driver-list", label: "All Drivers" },
        { to: "drivers/withdrawal", label: "Driver Withdrawals" },
        { to: "drivers/remit", label: "Driver Remittance" },
        { to: "drivers/payment", label: "Driver Payment Request" },
        { to: "drivers/driver-push-orders", label: "Driver Push Orders" },
        { to: "drivers/deletion-request", label: "Driver Deletion Request" },
        { to: "drivers/completed-payment", label: "Completed Payment" },
        { to: "drivers/promotion", label: "Driver Promotion" },
      ],
    },
    {
      icon: <FaUsers />,

      label: "Users",
      subNav: [
        { to: "user/user-list", label: "All Users" },
        { to: "user/user-transcation", label: "General Transcations" },
        { to: "user/vtu-transcation", label: "VTU Transactions" },
        { to: "user/to-up-wallets", label: "Top-up Wallet" },
        { to: "user/wallet-deduction", label: "Wallet Deduction" },
        { to: "user/shopping-coupon", label: "Shopping Coupon" },
        { to: "user/laundry-coupon", label: "Laundry Coupon" },
        { to: "user/isusu-coupon", label: "Isusu Coupon" },
        { to: "user/promotions", label: "User Promotions" },
        { to: "user/email-marketing", label: "Email Marketing" },
        { to: "user/auto-email-message", label: "Auto Email Message" },
        { to: "user/deletion-request", label: "User Deletion Request" },
        { to: "user/tippers", label: "Tippers" },
        { to: "user/daily-roi", label: "Daily ROI" },
        { to: "user/coupon-history", label: "Coupon History" },
        { to: "user/promotion-history", label: "Promotion History" },
        { to: "user/user-birthday", label: "User Birthdays" },
      ],
    },
    {
      icon: <SiTransportforlondon />,
      to: "transport",
      label: "Transport",
    },
    {
      icon: <FaGasPump />,
      to: "fuel",
      label: "Fuel & Gas",
    },
    {
      icon: <FaMapLocationDot />,

      label: "Location",
      subNav: [
        { to: "location/countries", label: "Countries" },
        { to: "location/states", label: "States" },
        { to: "location/province", label: "Cities" },
      ],
    },
    {
      icon: <BsFillShieldLockFill />,
      label: "Investment",
      subNav: [
        { to: "investment/investments", label: "Investments" },
        { to: "investment/investor", label: "Investors" },
        { to: "investment/project", label: "Projects" },
        { to: "investment/isusu", label: "Isusu" },
        {
          to: "investment/isusu-approval-request",
          label: "Isusu Approval Request",
        },
        { to: "investment/isusu-receivers", label: "Isusu Receivers" },
        { to: "investment/isusu-defaulter", label: "Isusu Defaulters" },
      ],
    },
    {
      icon: <MdAdminPanelSettings />,
      label: "Admin",
      subNav: [
        { to: "admin/admin", label: "Admins" },
        { to: "admin/admin-roles", label: "Admin Roles" },
        { to: "admin/assign-admin-functions", label: "Assign Admin Functions" },
      ],
    },
    {
      icon: <HiOutlineClipboardDocumentList />,
      label: "Legal",
      subNav: [
        { to: "legal/terms-and-conditions", label: "Terms & Conditions" },
        { to: "legal/privacy-and-policy", label: "Privacy Policy" },
      ],
    },
  ];

  // Filter navItems based on userFunctions
  const filteredNavItems =
    userRole === "Super Admin"
      ? allNavItems
      : allNavItems?.filter((navItem) => {
          if (userFunctions?.includes(navItem.label)) {
            return true;
          }
          if (navItem.subNav) {
            navItem.subNav = navItem.subNav?.filter((subItem) =>
              userFunctions?.includes(subItem.label)
            );
            return navItem.subNav?.length > 0;
          }
          return false;
        });

  return (
    <aside
      className={`${
        open
          ? "w-[340px] transition-width duration-300"
          : "w-28 transition-width duration-300"
      } relative bg-white text-gray-800 overflow-y-auto`}
    >
      <div className="p-4 mt-4 flex items-center gap-4 justify-between ">
        <div>
          <img
            src={open ? LogoImg : LogoImg2}
            alt="Logo"
            className=" h-7 cursor-pointer"
          />
          <BiMenuAltRight
            className="text-2xl text-gray-500 cursor-pointer absolute w-7 right-5 top-9"
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
      <nav className="p-4 mt-2 text-[14px] text-gray-700 font-[500]">
        <ul>
          <li
            className={`flex rounded-md p-2 cursor-pointer items-center gap-x-4 hover:bg-blue-100 focus:bg-blue-400 focus:text-blue-500`}
          >
            <BiSolidDashboard className=" text-2xl text-gray-600" />
            <Link
              to="/dashboard"
              className={`origin-left text-[14px] text-gray-700 font-[500] duration-200 ${
                !open && "hidden"
              }`}
            >
              <p>Dashboard</p>
            </Link>
          </li>
          {filteredNavItems.map((item) => (
            <React.Fragment key={item.label}>
              {item.subNav ? (
                <li
                  className="flex items-center p-2 hover:rounded-sm justify-center mb-2 transition duration-900 ease-in-out transform focus:rounded-lg hover:bg-blue-100 focus:bg-blue-400 focus:text-blue-500"
                  onClick={() => toggleSubNav(item.label)}
                >
                  <Link
                    to={item.to}
                    className="flex justify-center whitespace-nowrap items-center"
                  >
                    <span className="mr-4 text-2xl text-gray-600">
                      {item.icon}
                    </span>
                    {open && item.label}
                  </Link>
                  {open && (
                    <span className="ml-auto cursor-pointer">
                      {showSubNav[item.label] ? (
                        <FaChevronUp className="text-xs font-normal text-gray-400" />
                      ) : (
                        <FaChevronDown className="text-xs font-normal text-gray-400" />
                      )}
                    </span>
                  )}
                </li>
              ) : (
                <SidebarItem icon={item.icon} to={item.to}>
                  {open && item.label}
                </SidebarItem>
              )}
              {item.subNav && showSubNav[item.label] && (
                <ul className="ml-6">
                  {item.subNav.map((subNavItem) => (
                    <SidebarSubItem
                      key={subNavItem.label}
                      to={subNavItem.to}
                      label={subNavItem.label}
                      number={
                        (subNavItem.label === "Express Orders" &&
                        expressLaundryOrders.length !== 0
                          ? expressLaundryOrders.length.toString()
                          : null) ||
                        (subNavItem.label === "Shopper Withdrawals" &&
                        shopperWithdrawals.length !== 0
                          ? shopperWithdrawals.length.toString()
                          : null) ||
                        (subNavItem.label === "Shopper Remittance" &&
                        shopperRemits.length !== 0
                          ? shopperRemits.length.toString()
                          : null) ||
                        (subNavItem.label === "Shopper Payment Request" &&
                        shopperpayments.length !== 0
                          ? shopperpayments.length.toString()
                          : null) ||
                        (subNavItem.label === "Driver Withdrawals" &&
                        driverWithdrawals.length !== 0
                          ? driverWithdrawals.length.toString()
                          : null) ||
                        (subNavItem.label === "Driver Remittance" &&
                        driverRemits.length !== 0
                          ? driverRemits.length.toString()
                          : null) ||
                        (subNavItem.label === "Driver Payment Request" &&
                        driverpayments.length !== 0
                          ? driverpayments.length.toString()
                          : null)
                      }
                    />
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

const SidebarItem = ({ icon, to, children }) => {
  return (
    <li className="flex items-center mb-2 transition p-2 duration-300 ease-in-out transform hover:bg-blue-100 focus:bg-blue-400 focus:text-blue-500">
      {icon && <span className="mr-4 text-2xl text-gray-600">{icon}</span>}
      <Link to={to} className="flex-1">
        {children}
      </Link>
    </li>
  );
};

const SidebarSubItem = ({ to, label, number }) => {
  return (
    <li className="flex items-center mr-4 text-gray-600 ml-4 p-2 transition duration-300 ease-in-out transform hover:bg-blue-10 ">
      <Link to={to} className="flex-1 ">
        <div className="flex flex-row gap-1">
          <div> {label}</div>
          <div className="flex justify-center items-center">
            {number && (
              <div className="rounded-full bg-red-500 h-6 w-6  text-white flex items-center justify-center">
                {number}
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Sidebar;
