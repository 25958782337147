import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchTradefairById } from "../../redux/slices/tradefairsSlice";
import { HiChevronRight } from "react-icons/hi";

const PendingTradefairDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { tradefairDetails, status, error } = useSelector(
    (state) => state.tradefair
  );

  useEffect(() => {
    dispatch(fetchTradefairById(id));
  }, [dispatch, id]);



  const tradefair = tradefairDetails?.tradefair;
  const vendors = tradefairDetails?.vendors;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
          Pending Trade Fair Details
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Trade fairs
            </p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/tradefair/pending-tradefair">
              <p className="text-[14px] text-[#667085] font-medium">
                Pending Trade Fair
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
             Pending Trade Fair Details
            </p>
          </div>
        </div>
      </div>

      
      {status === "loading" && (
        <div className="space-y-4">
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <div className="h-8 bg-gray-300 rounded w-1/3 skeleton"></div>
            <div className="flex flex-col md:flex-row items-center mt-4">
              <div className="mb-4 md:mb-0 md:mr-4 w-full">
                <div className="h-4 bg-gray-300 rounded w-1/2 skeleton mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/3 skeleton mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4 skeleton mb-2"></div>
                <div className="h-4 bg-gray-300 rounded w-1/4 skeleton mb-2"></div>
                <div className="h-24 bg-gray-300 rounded skeleton"></div>
              </div>
              <div className="flex justify-center items-center w-full md:w-auto">
                <div className="w-64 h-64 bg-gray-300 rounded-lg skeleton"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {status === "failed" && <div>Error: {error}</div>}

    
      {tradefair && (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h1 className="text-2xl font-bold mb-4">{tradefair.name}</h1>
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className=" w-1/3">
              <p className="mb-2">
                <strong>Start Date:</strong> {tradefair.start_date}
              </p>
              <p className="mb-2">
                <strong>End Date:</strong> {tradefair.end_date}
              </p>
              <p className="mb-2">
                <strong>Location:</strong> {tradefair.location}
              </p>
              <p className="mb-2">
                <strong>Price:</strong> ₦ {tradefair.price}
              </p>
              <p className="mb-2">
                <textarea
                  name=""
                  id=""
                  rows="3"
                  cols="30"
                  value={tradefair.note}
                  className="border-2 rounded-lg p-2"
                ></textarea>
              </p>
            </div>
            <div className=" w-full md:w-auto">
              <img
                src={tradefair.image}
                alt={tradefair.name}
                className="w-64 h-64 object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
      {vendors && vendors.length > 0 && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-xl font-bold mb-4">Vendors</h2>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">#</th>
                <th className="py-2 px-4 border-b">Image</th>
                <th className="py-2 px-4 border-b">Name</th>
                <th className="py-2 px-4 border-b">Note</th>
                <th className="py-2 px-4 border-b">Approved</th>
                <th className="py-2 px-4 border-b">Reason</th>
              </tr>
            </thead>
            <tbody>
              {vendors.map((vendor, index) => (
                <tr key={vendor.id} className="text-center">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">
                    <div className="flex justify-center items-center">
                      <img
                        src={vendor.image}
                        alt={vendor.name}
                        className="w-16 h-16 object-cover rounded-lg"
                      />
                    </div>
                  </td>
                  <td className="py-2 px-4 border-b">{vendor.name}</td>
                  <td className="py-2 px-4 border-b">
                    <textarea
                      value={vendor.note}
                      readOnly
                      className="border-2 rounded-lg p-2 w-full"
                      rows="3"
                    ></textarea>
                  </td>
                  <td className="py-2 px-4 border-b">
                    {vendor.approved === "1" ? "Yes" : "No"}
                  </td>
                  <td className="py-2 px-4 border-b">{vendor.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PendingTradefairDetails;
